/** @format */

import React from "react";

import { Chart } from "./Chart";

function Token(props) {
  return (
    <div className="inner-page tf-section token">
      <section className="tf-section tf-token pt60">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div
                className="tf-title left mb40"
                data-aos-delay="500"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <h2 className="title">Token Statistics</h2>
              </div>
              <div
                className="desc-token"
                data-aos-delay="500"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <div className="total-token">
                  <p>
                    Total Supply: <span>100,000,000,000</span>
                  </p>
                  <p>
                    Private sale: <span> TBD</span>
                  </p>
                </div>
                <ul className="describe_chart">
                  <li>
                    <img
                      src={require("../../assets/images/chart/color_1.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Team</p>
                      <p className="number">5.0%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../../assets/images/chart/color_2.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Burn</p>
                      <p className="number">20.0%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../../assets/images/chart/color_3.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">CEX</p>
                      <p className="number">10.0%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../../assets/images/chart/color_4.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Liquidity</p>
                      <p className="number">40.0%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../../assets/images/chart/color_5.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Airdrop</p>
                      <p className="number">7.0%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../../assets/images/chart/color_6.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Marketing</p>
                      <p className="number">8.0%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../../assets/images/chart/color_7.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Private Sale</p>
                      <p className="number">10.0%</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div
                data-aos="fade-left"
                data-aos-delay="500"
                data-aos-duration="800"
              >
                <div id="chart">
                  <Chart />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Token;
