/** @format */

const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },

  {
    id: 1,
    name: "About",
    links: "#",
  },
  {
    id: 1,
    name: "Tokenomics",
    links: "/tokenomics",
  },

  {
    id: 4,
    name: "Roadmap",
    links: "",
  },

  {
    id: 6,
    name: "Whitepaper",
    links: "/PDFViewer",
  },
];

export default menus;
