/** @format */

import React from "react";
import "../scss/component/_section.scss";
import "../scss/component/_box.scss";
import "../scss/component/_tf-section.scss";
import "../scss/component/_reponsive.scss";
import Banner3 from "../components/banner/banner_v3";
import dataBanner from "../assets/fake-data/data-banner";
import Technology from "../components/technology";
import Token1 from "../components/token/Token";
import Roadmap from "../components/roadmap";
import dataRoadmap from "../assets/fake-data/data-roadmap";
import Faqs from "../components/faqs";
import dataFaq from "../assets/fake-data/data-faqs";
import Team from "../components/team";
import Partner3 from "../components/partner/Partner3";
import dataPartner from "../assets/fake-data/data-partner";
import CTA from "../components/cta";
import PDFViewer from "../components/pdf/PDFViewer";
import dataTeam from "../assets/fake-data/data-team";
function Home4(props) {
  return (
    <div className="header-fixed main home1 counter-scroll">
      {<Banner3 data={dataBanner} />}
      <br />
      <br />
      {/*{<Partner3 data={dataPartner} />}*/}
      {<Technology data={dataPartner} />}
      {<Token1 />}
      {<Roadmap data={dataRoadmap} />}
      {/*<Team data={dataTeam} />*/}
      {<Faqs data={dataFaq} />}
      <CTA />
    </div>
  );
}

export default Home4;
