

const dataFaq = [
    {
        id: 1,
        title: 'What is Mazukamba?',
        content: 'Mazukamba is the token used to pay fees in our launchpad The Nest.'
    }, 
    {
        id: 2,
        title: 'How to launch projects?',
        content: 'When the platform is ready we will have different options, from launching with an easy standard contract, to bringing your own.'
    },
    {
        id: 3,
        title: 'How can I participate in the private sale?',
        content: 'We will share the address to send STX to, and you will receive the Mazukamba tokens in return by the time we launch. Addresses must hold a minimum of 15 STX on their Leather or Xverse wallet.'
        //link:'https://go.nestprotocol.org/private-sale'
    },
    {
        id: 4,
        title: 'Where can I find more information?',
        content: 'You can always surf our site and join our social channels. Telegram is our main channel of communication'
    },
    {
        id: 5,
        title: 'What is Stacks?',
        content: 'We fully believe Stacks is the future and will open the doors for bigger things. It is a Defi protocol on top of Bitcoin. Their Clarity programming language makes smart contracts possible on Bitcoin.'
    },

    

]

export default dataFaq;