
const dataRoadmap = [
    {
        id: 1,
        time: 'Q1 2024',
        text1: 'Start marketing campaign',
        text2: 'Start DAPP development',
        text3: 'Discord community building',
        status: 'active'
    }, 
    {
        id: 2,
        time: 'Q2 2024',
        text1: 'Telegram community building',
        text2: 'Private sale',
        text3: 'Token launch',
        status: 'active'
    },
    {
        id: 3,
        time: 'Q3 2024',
        text1: 'Start relationships with potential partners',
        text2: 'Present project in web3 conferences',
        text3: 'Platform testing',
        status: 'active'
    },
    {
        id: 4,
        time: 'Q4 2024',
        text1: 'Further testing',
        text2: 'NFT Collection launch',
        text3: 'Beta launch of DAPP',
        status: 'active'
    },
    {
        id: 5,
        time: 'Q1 2025',
        text1: 'Official launch of DAPP',
        text2: 'Double projects promotion',
        text3: 'Start multi-chain bridge development',
        status: 'active'
    },
    {
        id: 6,
        time: 'Q2 2025',
        text1: 'Level up with new partnerships',
        text2: 'Coming soon...',
        text3: 'Coming soon...',
        status: ''
    },
    {
        id: 7,
        time: 'Q3 2025',
        text1: 'Coming soon..',
        text2: 'Coming soon...',
        text3: 'Coming soon...',
        status: ''
    },
    {
        id: 8,
        time: 'Q4 2025',
        text1: 'Coming soon..',
        text2: 'Coming soon...',
        text3: 'Coming soon...',
        status: ''
    },


]

export default dataRoadmap;