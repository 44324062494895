/** @format */

import React from "react";
import "../styles.scss";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";

Banner3.propTypes = {
  data: PropTypes.array,
};

function Banner3(props) {
  const { data } = props;
  return (
    <div className="page-title st3">
      <div className="overlay"></div>
      <div className="slider-main">
        <Swiper
          modules={[Navigation]}
          spaceBetween={0}
          slidesPerView={1}
          navigation
        >
          {data.slice(0, 2).map((item) => (
            <SwiperSlide key={item.id}>
              <div className="slider-st3">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="box-slider">
                        <div className="content-box">
                          <h1 className="title">Revolutionary DeFi Launchpad</h1>
                          <p className="sub-title">
                            You don't have to worry about hiring expensive developers. 
                            We make it simple.
                          </p>
                          <div className="wrap-btn">
                            <Link
                              to="/project_list"
                              className="tf-button style2"
                            >
                              LEARN MORE
                            </Link>
                          </div>
                          <div className="flat-box">
                            <div className="box-couter">
                              <p>Potential impact</p>
                              <div className="number-content">
                                <h6 className="count-number2">100M+</h6>
                              </div>
                            </div>
                            <div className="box-couter">
                              <p>Private Sale</p>
                              <div className="number-content">
                                <h6 className="count-number2">TBD</h6>
                              </div>
                            </div>
                            <div className="box-couter">
                              <p>Blockchain</p>
                              <div className="number-content">
                                <h6 className="count-number2">Stacks</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="image">
                          <img
                            className="img_main"
                            src={require("../../../assets/images/favicon.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Banner3;
