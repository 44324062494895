import React from 'react';
import "./styles.scss";


function PDFViewer() {
  return (
    <div className="pdf-container" style={{ paddingTop: '100px' }}>
      {/* Use process.env.PUBLIC_URL to correctly reference the file */}
      <iframe src={`${process.env.PUBLIC_URL}/assets/whitepaper/Mazukamba-whitepaper-draft.pdf`} width="100%" height="800px" />
    </div>
  );
}

export default PDFViewer;
