import img1 from '../images/slider/Furore.png';
import img2 from '../images/slider/icon_1.png';
import img3 from '../images/slider/icon_2.png';
import img4 from '../images/slider/icon_3.png';

const dataBanner = [
    {
        id: 1,
        heading: '',
        sub_heading: '',
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    }, 
    {
        id: 2,
        heading: '',
        sub_heading: '',
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    }
]

export default dataBanner;